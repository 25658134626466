:root {
  --highlight-light: #9466e8;
  --highlight-dark: #fec006;
  --dark-gray: #2b2b2b;
  --black: #111;
}

html {
  height: -webkit-fill-available;
  font-family: Inter var, Inter, -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Open Sans, Helvetica Neue, Calibri, Tahoma, sans-serif;
}

body {
  color: var(--black);
  height: -webkit-fill-available;
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

nav {
  padding: 16px;
  position: absolute;
}

#logo {
  background-image: url("https://padlet.net/logos/wordmark_111111_298x72.png"), url("https://padlet.net/logos/wordmark_rainbow_298x72.png"), url("https://padlet.net/logos/wordmark_purple_298x72.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: contain, contain, contain;
}

a {
  color: inherit;
}

@media (hover: hover) {
  a:hover {
    text-decoration-color: var(--highlight-light);
  }
}

a:active {
  color: var(--highlight-light);
}

a #logo {
  cursor: pointer;
}

@media (hover: hover) {
  a #logo:hover {
    background-image: url("https://padlet.net/logos/wordmark_rainbow_298x72.png");
  }
}

a #logo:active {
  background-image: url("https://padlet.net/logos/wordmark_purple_298x72.png");
}

a.button {
  color: #fff;
  background-color: #ff4081;
  border-radius: 1rem;
  padding: 0 20px;
  font-size: 17px;
  font-weight: 600;
  line-height: 2.5rem;
  text-decoration: none;
  display: inline-block;
}

@media (hover: hover) {
  a.button:hover {
    background-color: #f01b68;
  }

  a.button:active {
    background-color: var(--highlight-light);
  }
}

section {
  text-align: center;
  height: 100vh;
  height: -webkit-fill-available;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.hidden {
  display: none;
}

.light-teal {
  background-color: #bbfff3;
}

.light-pink {
  background-color: #ffebf3;
}

.light-purple {
  background-color: #eae4f0;
}

.light-green {
  background-color: #deffdd;
}

.light-gray {
  background-color: #ebecef;
}

.light-blue {
  background-color: #def5ff;
}

.light-orange {
  background-color: #fff5ef;
}

.light-tangerine {
  background-color: #ffe9c8;
}

.light-scarlet {
  background-color: #fff2f2;
}

h1 {
  max-width: 540px;
  margin: 0 0 1rem;
  padding: 144px 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25em;
}

p {
  max-width: 540px;
  margin: 0;
  padding: 0 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.4;
}

.action {
  margin-top: 2rem;
}

.caption-text {
  color: #0000008a;
}

@media (min-width: 1728px) {
  img.footer {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 743px) {
  img.footer {
    height: 192px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: var(--dark-gray);
  }

  @media (hover: hover) {
    a:hover {
      text-decoration-color: var(--highlight-dark);
    }
  }

  a:active {
    color: var(--highlight-dark);
  }

  #logo {
    background-image: url("https://padlet.net/logos/wordmark_ffffff_298x72.png"), url("https://padlet.net/logos/wordmark_rainbow_298x72.png"), url("https://padlet.net/logos/wordmark_yellow_298x72.png");
  }

  a #logo:active {
    background-image: url("https://padlet.net/logos/wordmark_yellow_298x72.png");
  }

  .dark-teal {
    background-color: #006379;
  }

  .dark-pink {
    background-color: #5f2340;
  }

  .dark-blue {
    background-color: #36419a;
  }

  .dark-purple {
    background-color: #513f69;
  }

  .dark-green {
    background-color: #003c2a;
  }

  .dark-gray {
    background-color: var(--dark-gray);
  }

  .dark-oceanic {
    background-color: #00418e;
  }

  .dark-scarlet {
    background-color: #530000;
  }

  .caption-text {
    color: #ffffff80;
  }

  @media (hover: hover) {
    a.button:active {
      background-color: var(--highlight-dark);
      color: var(--black);
    }
  }
}

/*# sourceMappingURL=style.f57537d0.css.map */
